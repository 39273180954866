import React, { useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { Container } from "reactstrap"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import google from "../../images/google-review.png"

const OncolTestimonials = ({ data, location }) => {
  const testimonials = data.allSanityOncologytestimonials.nodes

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.async = true
    script.defer = true
    document.head.appendChild(script)

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script)
    }
  }, [])
  return (
    <Layout
      title="Oncology Testimonials"
      location={location}
      page="oncology-testimonials"
    >
      <SEO
        title="Oncology Testimonials"
        description="Simply put, it’s a network of professionals and business services that enables us to provide the best possible service to our candidates and clients."
      />
      <div id="ts-page">
        <div className="page-headline">
          <Container>
            <h1 className="bottom-underline text-blue text-center">
              TESTIMONIALS
            </h1>
            {/* <div className="google-review-image text-center mx-auto my-5">
              <img className="w-75 img-fluid mx-auto" src={google} alt="" />
            </div> */}
            <div className="google-review home-review">
              <div
                className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
                data-elfsight-app-lazy
                // data-embed-id="25369363"
              ></div>
              {/* <div className="overlay" style={{ height: "30%" }} /> */}
            </div>

            <div className="">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry>
                  {testimonials
                    .sort((a, b) => a.order - b.order) // Sort testimonials by order field ascending
                    .map((test, index) => (
                      <div key={index} className="testimonial-mason">
                        <ul
                          className={`list-unstyled text-right ratings-${
                            test.rating ? test.rating : 5
                          }`}
                        >
                          {[...Array(5)].map((
                            _,
                            i // Using Array to render stars dynamically
                          ) => (
                            <li key={i} className="d-inline">
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                          ))}
                        </ul>
                        {test?.Message && <p>{test?.Message}</p>}
                        {test?.name && (
                          <h6 className="mb-0" style={{ color: "#176588" }}>
                            {test?.name}
                            {test?.designation && <>,</>}
                          </h6>
                        )}

                        {test?.designation && (
                          <p style={{ color: "#176588" }}>
                            {test?.designation}
                          </p>
                        )}
                      </div>
                    ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default OncolTestimonials

export const query = graphql`
  query {
    allSanityOncologytestimonials {
      nodes {
        id
        order
        name
        rating
        Message
        designation
      }
    }
  }
`
